var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-menu",
      class: [_vm.customClass, "theme-" + _vm.theme]
    },
    [
      !_vm.user
        ? _c(
            "div",
            { staticClass: "auth-links" },
            [
              _c(
                "router-link",
                { staticClass: "auth-link", attrs: { to: "/signin" } },
                [
                  _vm._v("👤 "),
                  _c("span", { staticClass: "auth-text" }, [
                    _vm._v(_vm._s(_vm.$t("auth.signin")))
                  ])
                ]
              ),
              _vm._v(" "),
              !_vm.compact
                ? _c("span", { staticClass: "separator" }, [_vm._v("/")])
                : _vm._e(),
              _vm._v(" "),
              !_vm.compact
                ? _c(
                    "router-link",
                    { staticClass: "auth-link", attrs: { to: "/signup" } },
                    [
                      _vm._v("✨ "),
                      _c("span", { staticClass: "auth-text" }, [
                        _vm._v(_vm._s(_vm.$t("auth.signup")))
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _c(
            "div",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.closeDropdown,
                  expression: "closeDropdown"
                }
              ],
              staticClass: "user-dropdown"
            },
            [
              _c(
                "button",
                {
                  staticClass: "dropdown-trigger",
                  on: { click: _vm.toggleDropdown }
                },
                [
                  _vm._v("\n      👤 "),
                  _c("span", { staticClass: "user-email" }, [
                    _vm._v(_vm._s(_vm.user.email))
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "arrow",
                      class: { "arrow-up": _vm.isDropdownOpen }
                    },
                    [_vm._v("▼")]
                  )
                ]
              ),
              _vm._v(" "),
              _vm.isDropdownOpen
                ? _c(
                    "div",
                    { staticClass: "dropdown-menu" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/my-cards" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.closeDropdown.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "item-icon" }, [
                            _vm._v("💌")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.$t("auth.myCards")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/account" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.closeDropdown.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "item-icon" }, [
                            _vm._v("⚙️")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.$t("auth.account")))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item",
                          attrs: { to: "/logout" },
                          nativeOn: {
                            click: function($event) {
                              return _vm.closeDropdown.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "item-icon" }, [
                            _vm._v("🚪")
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "item-text" }, [
                            _vm._v(_vm._s(_vm.$t("auth.logout")))
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0ea0e664", { render: render, staticRenderFns: staticRenderFns })
  }
}