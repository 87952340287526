var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showHeader
        ? _c("header", { staticClass: "app-header", class: _vm.headerClass }, [
            _c(
              "div",
              { staticClass: "header-content" },
              [
                _vm._m(0),
                _vm._v(" "),
                _vm.showUserMenu
                  ? _c("UserMenu", {
                      attrs: {
                        "custom-class": _vm.userMenuClass,
                        theme: _vm.userMenuTheme
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "logo", attrs: { href: "/" } }, [
      _c("img", { attrs: { src: "/images/logo/logo.svg", alt: "Sharewell" } })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8399f25c", { render: render, staticRenderFns: staticRenderFns })
  }
}