<template>
  <div class="user-menu" :class="[customClass, `theme-${theme}`]">
    <!-- Logged out state -->
    <div v-if="!user" class="auth-links">
      <router-link to="/signin" class="auth-link">👤 <span class="auth-text">{{ $t('auth.signin') }}</span></router-link>
      <span class="separator" v-if="!compact">/</span>
      <router-link to="/signup" class="auth-link" v-if="!compact">✨ <span class="auth-text">{{ $t('auth.signup') }}</span></router-link>
    </div>

    <!-- Logged in state -->
    <div v-else class="user-dropdown" v-click-outside="closeDropdown">
      <button class="dropdown-trigger" @click="toggleDropdown">
        👤 <span class="user-email">{{ user.email }}</span>
        <span class="arrow" :class="{ 'arrow-up': isDropdownOpen }">▼</span>
      </button>
      <div v-if="isDropdownOpen" class="dropdown-menu">
        <router-link to="/my-cards" class="dropdown-item" @click.native="closeDropdown">
          <span class="item-icon">💌</span>
          <span class="item-text">{{ $t('auth.myCards') }}</span>
        </router-link>
        <router-link to="/account" class="dropdown-item" @click.native="closeDropdown">
          <span class="item-icon">⚙️</span>
          <span class="item-text">{{ $t('auth.account') }}</span>
        </router-link>
        <router-link to="/logout" class="dropdown-item" @click.native="closeDropdown">
          <span class="item-icon">🚪</span>
          <span class="item-text">{{ $t('auth.logout') }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserMenu',
  props: {
    customClass: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'default' // can be 'default', 'light', 'dark', etc.
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      user: null,
      isDropdownOpen: false
    };
  },
  created() {
    this.fetchUser();
    // Listen for separate login/logout events
    this.$root.$on('auth:login', this.handleLogin);
    this.$root.$on('auth:logout', this.handleLogout);
  },
  beforeDestroy() {
    // Clean up listeners
    this.$root.$off('auth:login', this.handleLogin);
    this.$root.$off('auth:logout', this.handleLogout);
  },
  methods: {
    async fetchUser() {
      try {
        const response = await axios.get('/auth/me');
        this.user = response.data.user;
      } catch (error) {
        // Clear user data on 401 or other errors
        this.user = null;
      }
    },
    async handleLogin(callback) {
      await this.fetchUser();
      if (callback) callback();
    },
    handleLogout(callback) {
      this.user = null;
      this.closeDropdown();
      if (callback) callback();
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    }
  },
  directives: {
    // This directive detects clicks outside of a specified element
    // Used to close the user dropdown menu when clicking anywhere else on the page
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function(event) {
          // If click is outside the element, call the provided handler
          if (!(el === event.target || el.contains(event.target))) {
            binding.value(event);
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        // Clean up event listener when directive is removed
        document.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  }
};
</script>

<style scoped>
.user-menu {
  position: relative;
  display: inline-block;
}

/* Theme variations */
.user-menu.theme-light .auth-link,
.user-menu.theme-light .dropdown-trigger {
  color: white;
}

.user-menu.theme-dark .auth-link,
.user-menu.theme-dark .dropdown-trigger {
  color: #333;
}

.auth-links {
  display: flex;
  align-items: center;
  gap: 8px;
}

.auth-link {
  color: #333;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s;
}

.auth-link:hover {
  color: #ea525c;
}

.separator {
  color: #666;
}

.dropdown-trigger {
  background: none;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.dropdown-trigger:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.arrow {
  font-size: 10px;
  transition: transform 0.2s;
}

.arrow-up {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 180px;
  margin-top: 4px;
  z-index: 1000;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s;
}

.item-icon {
  flex: 0 0 1.5em;
  text-align: center;
  margin-right: 8px;
}

.item-text {
  flex: 1;
}

.dropdown-item:hover {
  background-color: #fff8f4;
}

.dropdown-item:first-child {
  border-radius: 4px 4px 0 0;
}

.dropdown-item:last-child {
  border-radius: 0 0 4px 4px;
}
</style> 