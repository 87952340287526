'use strict';

import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from './i18n';
import App from './App.vue';
import axios from 'axios';

import VueSanitize from "vue-sanitize";
Vue.use(VueSanitize);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

const routes = [
	{ path: '/signup', component: () => import(/* webpackChunkName: "signup" */ './account/signup.vue') },
	{ path: '/signin', component: () => import(/* webpackChunkName: "signin" */ './account/signin.vue') },
	{ path: '/logout', component: () => import(/* webpackChunkName: "logout" */ './account/logout.vue') },
	{ path: '/forgot-password', component: () => import(/* webpackChunkName: "forgot-password" */ './account/forgot-password.vue') },
	{ path: '/reset-password', component: () => import(/* webpackChunkName: "reset-password" */ './account/reset-password.vue') },
	{ path: '/my-cards', component: () => import(/* webpackChunkName: "my-cards" */ './account/my-cards.vue') },
	{ path: '/account', component: () => import(/* webpackChunkName: "account" */ './account/account.vue') },
	{ path: '/card', component: () => import(/* webpackChunkName: "create-card" */ './create_card.vue') },
	{ path: '/card_created/:code', component: () => import(/* webpackChunkName: "card-created" */ './card_created_confirmation.vue') },
	{ path: '/message/:occasion/:recipient/:code', component: () => import(/* webpackChunkName: "create-message" */ './create_message.vue') },
	{ path: '/message/:code', component: () => import(/* webpackChunkName: "create-message" */ './create_message.vue') },
	{ path: '/message_created/:code', component: () => import(/* webpackChunkName: "message-created" */ './message_created_confirmation.vue') },
	{
		path: '/card/:occasion/:recipient/:code/:admin_code',
		component: () => import(/* webpackChunkName: "finish-card" */ './finish_card.vue'),
		meta: { hideHeader: true }
	},
	{
		path: '/card/:code/:admin_code',
		component: () => import(/* webpackChunkName: "finish-card" */ './finish_card.vue'),
		meta: { hideHeader: true }
	},
	{ path: '/rendered_card/:code/:admin_code', component: () => import(/* webpackChunkName: "rendered-card" */ './rendered_card.vue') },
	{ path: '/plane', component: () => import(/* webpackChunkName: "paper-plane" */ './paper_plane_animation.vue') },
	{ path: '/loader', component: () => import(/* webpackChunkName: "loader" */ './loader.vue') },
	{ path: '/metrics', component: () => import(/* webpackChunkName: "metrics" */ './metrics.vue') },
	{ path: '/faq', component: () => import(/* webpackChunkName: "faq" */ './faq.vue') },
];

const router = new VueRouter({
	routes,
	mode: 'history',
	base: '/',
});

Vue.use(VueRouter);

// Add navigation guard for auth routes
router.beforeEach(async (to, from, next) => {
	// Define auth routes that should redirect when logged in
	const authRoutes = ['/signin', '/signup', '/forgot-password'];
	
	if (authRoutes.includes(to.path)) {
		try {
			// Check if user is authenticated
			const response = await axios.get('/auth/me');
			if (response.data.user) {
				// User is authenticated, redirect to my-cards
				return next('/my-cards');
			}
		} catch (error) {
			// User is not authenticated, proceed to auth route
		}
	}
	next();
});

new Vue({
	router,
	i18n,
	render: h => h(App)
}).$mount('#app');
